<script>
import AlertDiscardChanges from "@/components/access-control/alert-discard-changes";
import {sha1} from "@/utils/object-hash.js";
import {debounce} from "lodash";

export default {
  name: "DashboardEditFormBase",
  data: (self) => {
    return {
      formMonitor: {
        name: self?.$options?.name ?? "generic_form",
        new_hash: "",
        old_hash: "",
        dirty: false
      }
    };
  },
  computed: {
    processAreas() {
      return this.$store.getters["processArea/processAreas"] || [];
    },
    contract() {
      return this.$store.getters["user/contract"];
    },
    hash: {
      set(val) {
        // the payload hash must be assigned using this property
        if (this.formMonitor.old_hash === "") {
          this.formMonitor.old_hash = val;
        }
        this.formMonitor.new_hash = val;
        this.formMonitor.dirty =
          this.formMonitor.new_hash !== "" &&
          this.formMonitor.old_hash !== "" &&
          this.formMonitor.new_hash !== this.formMonitor.old_hash;
        // console.log(this.formMonitor.dirty);
      },
      get() {
        return this.formMonitor.new_hash;
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (await this.confirmDiscardChanges()) {
      next();
    } else {
      next(false);
    }
  },
  methods: {
    allowDiscardChanges(val) {
      let query = this.$store.getters["equipment/query"] || {};
      if (val !== undefined) {
        query.discard = query.discard || {};
        query.discard[this.formMonitor.name] = val;
        this.$store.dispatch("equipment/setQuery", query);
      }
      return (query?.discard || {})[this.formMonitor.name] ?? false;
    },
    async confirmDiscardChanges() {
      if (this.allowDiscardChanges()) return true;
      else if (this.formMonitor.dirty) {
        const ok = await this.$swal({
          title: this.$t("discard_changes"),
          content: this.discardAlert(),
          icon: "warning",
          buttons: [this.$t("cancel"), this.$t("continue")],
          dangerMode: true
        });
        if (ok) this.formMonitor.dirty = false;
        return ok;
      }
      return true;
    },
    discardAlert() {
      const alert = new AlertDiscardChanges({
        el: document.createElement("div"),
        i18n: this.$i18n,
        propsData: {ask: this.allowDiscardChanges()}
      });
      alert.$on("input", (val) => this.allowDiscardChanges(val));
      return alert.$el;
    }
  },
  created() {
    this.updateHash = debounce((val) => (this.hash = sha1(val)), 1000);
    this.resetHash = () => {
      this.formMonitor.new_hash = "";
      this.formMonitor.old_hash = "";
      this.formMonitor.dirty = false;
    };
  }
};
</script>
